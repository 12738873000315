import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Button from "../atoms/button.atom"
import Tagline from "../atoms/tagline.atom"
import FormattedDate from "../atoms/formatted-date.atom"
import withSquircle from "../hocs/with-squircle.hoc"
import theme from "../../theme"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

// Normal Article

const Article = styled.article`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;

  p {
    margin-bottom: 20px;
  }

  header {
    h2 {
      margin-bottom: 0px;
    }
    p:last-child {
      font-size: 13.33px;
    }
  }
  transition: all .5s ease;

  ${Button} {
    align-self: flex-end;
  }

  ${theme.media.tablet`
    ${Button} {
      width: 100%;
    }
  `}
`

const Text = styled.div`
  padding: 20px 10px 0px;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  align-items: flex-start;
`
const SquircleLink = withSquircle(styled(Link)`
  max-width: 100%;
  display: block;
`)


export const PostPreviewColumn = React.forwardRef(({post, isFeatured}, ref) => {
  const image = getImage(post.fields.featuredImageRelative)
  return (
    <Article ref={ref}>
      <SquircleLink squircle={theme.squircle.fixed.medium} to={post.fields.slug}><GatsbyImage image={image} alt={post.frontmatter.headline} title={post.frontmatter.headline}/></SquircleLink>
      <Text>
      <header>
        <Tagline>{post.frontmatter.tagline}</Tagline>
        <h2><Link to={post.fields.slug}>{post.frontmatter.headline}</Link></h2>
        <p><FormattedDate date={post.frontmatter.date}/></p>
      </header>
      <p className="post-description">{post.frontmatter.description}</p>
      <Button to={post.fields.slug}>Weiterlesen</Button>
      </Text>
    </Article>
  )
})

export default PostPreviewColumn