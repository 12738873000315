import React from "react"
import styled, { css } from "styled-components"
import withSize from "./with-size.hoc"
import theme from "../../theme"
import { capitalize } from "../util"



export const withSquircle = (WrappedComponent) => {

  const Squircle = styled(WrappedComponent)`
    ${props => props.$clipPathDefault && css`
      clip-path: path('${props.$clipPathDefault}');
    `}
    ${props => props.$fallbackRadiusDefault && css`
      border-radius: ${props.$fallbackRadiusDefault};
    `}
    
    ${props => props.$clipPathDesktop && css`
      ${theme.media.desktop`
        clip-path: path('${props.$clipPathDesktop}');
      `}
    `}
    ${props => props.$fallbackRadiusDesktop && css`
      ${theme.media.desktop`
        border-radius: ${props.$fallbackRadiusDesktop};
      `}
    `}
  
    ${props => props.$clipPathTablet && css`
      ${theme.media.tablet`
        clip-path: path('${props.$clipPathTablet}');
      `}
    `}
    ${props => props.$fallbackRadiusTablet && css`
      ${theme.media.tablet`
        border-radius: ${props.$fallbackRadiusTablet};
      `}
    `}

      ${props => props.$clipPathMobile && css`
        ${theme.media.mobile`
          clip-path: path('${props.$clipPathMobile}');
        `}
      `}
      ${props => props.$fallbackRadiusMobile && css`
        ${theme.media.mobile`
          border-radius: ${props.$fallbackRadiusMobile};
        `}
      `}
  `

  return withSize(React.forwardRef((props, ref) => {
    const {size, squircle = {default: Infinity, desktop: Infinity, tablet: Infinity, mobile: Infinity}, ...otherProps} = props
    const {width, height} = size
    let fallbackRadius = {}
    let clipPaths = {}
    if(width > 0 && height > 0) {
      Object.keys(squircle).forEach(key  => {
        const distance = squircle[key]
        const offset = {x: Math.min(width/2, distance), y: Math.min(height/2, distance)}
        const handleLength = {x: offset.x * (3/4), y: offset.y * (3/4),}
        const control = {x: offset.x - Math.min(offset.x, handleLength.x), y: offset.y - Math.min(offset.y, handleLength.y)}
        clipPaths[`$clipPath${capitalize(key)}`] = `
          M 0,${offset.y}
          C 0,${control.y} ${control.x},0 ${offset.x},0
          L ${width - offset.x},0
          C ${width - control.x},0 ${width},${control.y} ${width},${offset.y}
          L ${width},${height - offset.y}
          C ${width},${height - control.y} ${width - control.x},${height} ${width - offset.x},${height}
          L ${offset.x},${height}
          C ${control.x},${height} 0,${height - control.y} 0,${height - offset.y}
          Z
        `.replace(/(\r\n|\n|\r)/gm,"")
      })
    } else {
      Object.keys(squircle).forEach(key  => {
        const distance = squircle[key]
        fallbackRadius[`$fallbackRadius${capitalize(key)}`] = `${(3/4) * distance}px` // TODO infinity bug ?
      })
    }
    

    return (
      <React.Fragment>
        <Squircle {...clipPaths} {...fallbackRadius} ref={ref} {...otherProps} />
      </React.Fragment>
    )
  }))
}

export default withSquircle