import React from "react"
import Helmet from "react-helmet"
import { graphql } from "gatsby"
import Layout  from "../organisms/layout.organism"
import PostsGrid from "../organisms/posts-grid.organism"
import PostPreviewFullScreen from "../molecules/post-preview-fullscreen.molecule"

export default function BlogTemplate({ data }) {
  const { edges: posts } = data.allMdx
  const meta = data.site.siteMetadata;
  const featuredPost = posts[0].node;
  const remainingPosts = posts.slice(1);

  return (
    <>
    {/* <img src={landingBg} /> */}
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{meta.title}</title>
        <meta name="description" content={meta.description} />
        <link rel="canonical" href="https://bloomista.garden/blog" />
      </Helmet>
      {/* <Quote from="Henri Matisse">Es gibt überall Blumen für den, der sie sehen will.</Quote> */}
      <PostPreviewFullScreen post={featuredPost}/>
      <PostsGrid title="Neuste Artikel" posts={remainingPosts}/>
    </Layout>
    </>
  )
}

export const pageQuery = graphql`
  query blog($now: Date) {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMdx(filter: {fileAbsolutePath: {regex: "/src\/posts/"}, frontmatter: {date: {lte: $now}}}, sort: {fields: frontmatter___date, order: DESC})  {
      edges {
        node {
          id
          excerpt(pruneLength: 600, truncate: false)
          frontmatter {
            headline
            tagline
            featuredImage
            date
            description
          }
          fields {
            slug
            featuredImageRelative {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
  }
`