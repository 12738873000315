import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Button from "../atoms/button.atom"
import Tagline from "../atoms/tagline.atom"
import FormattedDate from "../atoms/formatted-date.atom"
import withSquircle from "../hocs/with-squircle.hoc"
import theme from "../../theme"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

// Featured Article
// TODO transition class
const FeaturedImage = styled(GatsbyImage)`
  max-width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  // transition: all .5s ease;
  // &:hover {
  //   transform: translateY(-10px);
  // }
`

const FeaturedImageContainer = styled.div`
  grid-column: span 1;
  a {
    height: 100%;
    position: relative;
  }
`
const FeaturedArticle = styled.article`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px;
  width: 100%;
  margin-bottom: 40px;
  ${theme.media.tablet`
    grid-template-columns: 1fr;
    grid-gap: 20px;

    ${Button} {
      display: block;
    }
  `}

`
const FeaturedText = styled.div`
  grid-column: span 1;
  p {
    margin-bottom: 20px;
  }
  ${theme.media.tablet`
    padding: 0px 10px;
  `}
`
const FeaturedHeader = styled.header`
  h2 {
    margin-bottom: 0px;
  }
  p:last-child {
    font-size: 13.33px;
  }
`

const SquircleLink = styled(withSquircle(Link))`
  && {
    display: block;
  }
`

export const PostPreviewRow = React.forwardRef(({post}, ref) => {
  const image = getImage(post.fields.featuredImageRelative)
  return (
    <FeaturedArticle as={'article'} className="full-width" ref={ref}>
      <FeaturedImageContainer>
        <SquircleLink squircle={theme.squircle.fixed.medium} to={post.fields.slug}><FeaturedImage  image={image} alt={post.frontmatter.headline}  title={post.frontmatter.headline}/></SquircleLink>
      </FeaturedImageContainer>
      <FeaturedText>
        <FeaturedHeader>
          <Tagline>{post.frontmatter.tagline}</Tagline>
          <h2><Link to={post.fields.slug}>{post.frontmatter.headline}</Link></h2>
          <p><FormattedDate date={post.frontmatter.date}/></p>
        </FeaturedHeader>
        <p>{post.excerpt}</p>
        <Button to={post.fields.slug} $moveonhover={true}>Weiterlesen</Button>
      </FeaturedText>
    </FeaturedArticle>
  )
})

export default PostPreviewRow