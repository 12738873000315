const convertToKebabCase = (string) => string
  .replace(/[^a-zA-Z0-9-\s]/g, '')
  .replace(/([A-Z])([A-Z])/g, '$1-$2')
  .replace(/([a-z])([A-Z])/g, '$1-$2')
  .replace(/[\s_]+/g, '-')
  .toLowerCase() 

const convertUmlautsToVowels = (string) => string
  .replace(/Ä/g, 'Ae')
  .replace(/ä/g, 'ae')
  .replace(/Ü/g, 'Ue')
  .replace(/ü/g, 'ue')
  .replace(/Ö/g, 'Oe')
  .replace(/ö/g, 'oe')

const convertToID = (string) => convertToKebabCase(convertUmlautsToVowels(string))

const convertToAnchorLink = (string) => "#" + convertToID(string)

const convertToAlphanumeric = (string) => string
  .replace(/[^0-9a-z]/gi, '')

const capitalize = (string) => string.charAt(0).toUpperCase() + string.slice(1)

module.exports.convertToKebabCase = convertToKebabCase;
module.exports.convertUmlautsToVowels = convertUmlautsToVowels;
module.exports.convertToID = convertToID;
module.exports.convertToAnchorLink = convertToAnchorLink;
module.exports.convertToAlphanumeric = convertToAlphanumeric;
module.exports.capitalize = capitalize;