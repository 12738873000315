import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Tagline from "../atoms/tagline.atom"
import FormattedDate from "../atoms/formatted-date.atom"
import { Container } from "../atoms/container.atom"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import theme from "../../theme"

// Featured Article
// TODO transition class
const FeaturedImage = styled(GatsbyImage)`
  width: 100vw;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
`

const Article = styled.article`
  width: 100%;
  height: calc(100vh - 90px);
  overflow: hidden;
  position: relative;
  margin-bottom: 40px;
`

const FeaturedHeader = styled.header`
  position: absolute;
  bottom: 80px;
  background: #00000070;
  box-shadow: 0px 0px 100px 100px #00000070;
  * {
    color: white;
  }

  ${theme.media.tablet`
    padding-right: 20px;
  `}
`


export const PostPreviewFullScreen = React.forwardRef(({post}, ref) => {
  const image = getImage(post.fields.featuredImageRelative)
  return (
    <Article ref={ref}>
      <a href={post.fields.slug}><FeaturedImage image={image} alt={post.frontmatter.headline}  title={post.frontmatter.headline}/></a>
      <Container>
        <FeaturedHeader>
          <Tagline large>{post.frontmatter.tagline}</Tagline>
          <h1><Link to={post.fields.slug}>{post.frontmatter.headline}</Link></h1>
          <p><FormattedDate date={post.frontmatter.date}/></p>
        </FeaturedHeader>
      </Container>
    </Article>
  )
})

export default PostPreviewFullScreen