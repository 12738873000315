import styled from 'styled-components'
import theme from '../../theme'

export const Container = styled.div`
  max-width: calc(1137px + 80px);
  padding: 40px;
  margin: 0 auto;
  display: block;

  ${theme.media.tablet`
    padding: 20px;
  `}
`