import styled from "styled-components"

// TODO specificity
export const Tagline = styled.p`
  margin-bottom: 0!important;
  text-transform: uppercase;
  font-weight: 500;
  font-size: ${props => props.large ? "1rem" : "0.8rem"};
  font-style: italic;
`

export default Tagline