import React from "react"
import styled from 'styled-components'
import PostPreviewRow from "../molecules/post-preview-row.molecule"
import PostPreviewColumn from "../molecules/post-preview-column"
import { Container } from "../atoms/container.atom"
import theme from "../../theme"

const Section = styled(Container)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 40px;

  ${theme.media.desktop`
    grid-template-columns: repeat(2, 1fr);
  `}

  ${theme.media.tablet`
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
  `}

`

const Heading = styled.h1`
  margin-bottom: 0;
  text-transform: uppercase;
  font-size: 1rem;
  grid-column: 1/-1;
`

export const PostsGrid = ({title, posts}) => {
  const expandedPost = posts[0].node;
  const remainingPosts = posts.slice(1);
  return (
    <Section as={"section"}>
      <Heading>{title}</Heading>
      <PostPreviewRow post={expandedPost} />
      {remainingPosts && remainingPosts.map(({node: post}) => <PostPreviewColumn  key={post.id} post={post} />)}
    </Section>
  )
}

export default PostsGrid