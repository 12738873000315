import React from "react"
import ReactDOM from "react-dom"

/**
 * HOC to pass pixel size to passed component
 * Requires Wrapped component to be able to receive a ref
 * 
 * @param {React.Component} WrappedComponent 
 * @returns The WrappedComponent with enhanced prop size
 */
export const withSize = (WrappedComponent) => {

  // Measure the actual size of the rendered element
  const measureElement = element => {
    const DOMNode = ReactDOM.findDOMNode(element);
    return {
      width: DOMNode?.offsetWidth,
      height: DOMNode?.offsetHeight,
    };
  }
  
  // Return enhanced component
  return React.forwardRef((props, ref) => {
    const wrappedRef = React.useRef(null)
    React.useImperativeHandle(ref, () => wrappedRef.current)

    const [size, setSize] = React.useState({width: 0, height: 0});
    const triggerMeasure = () => {
      // Set timeout to ensure the React DOMNode has the correct size
      // setTimeout(() => setSize(measureElement(wrappedRef?.current)), 100)
      setSize(measureElement(wrappedRef?.current))
    }

    React.useEffect(() => {
      // Set initial size when mounting the component
      setTimeout(() => triggerMeasure(), 200)

      window.addEventListener('resize', triggerMeasure)
      
      // Cleanup, remove window event listener
      return () => {
        window.removeEventListener('resize', triggerMeasure)
      }
    }, [])

    return (
      <WrappedComponent ref={wrappedRef} size={size} {...props} />
    )
  })
}

export default withSize