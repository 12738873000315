import { Link } from "gatsby"
import styled from "styled-components"
import theme from "../../theme"

export const Button = styled(Link)`
  background: ${theme.colors.grey.extralight};
  transition: transform .5s ease, background .5s ease;
  &:hover {
    background: ${theme.colors.grey.light};
    /*${props => props.$moveonhover ? "transform: translateY(-5px);" : ""}*/
    text-decoration: none;
  }
  color: ${theme.colors.grey.dark};
  display: inline-block;
  line-height: 44px;
  padding: 0px 44px;
  border-radius: 22px;
  font-size: 12px;
  border: 1px solid ${theme.colors.grey.light};
  text-align: center;
`

export default Button